const DevCook = {
  s3: { MEDIABUCKET: 'https://s3.amazonaws.com/mobileapp-devcook-mediabucket' },
  apiGateway: { URL: 'https://api.northstrat-app.com/devcook' }
};

const DevAbbott = {
  s3: { MEDIABUCKET: 'https://s3.amazonaws.com/mobileapp-devabbott-mediabucket' },
  apiGateway: { URL: 'https://api.northstrat-app.com/devabbott' }
};

const test = {
  s3: { MEDIABUCKET: 'https://s3.amazonaws.com/mobileapp-test-mediabucket' },
  apiGateway: { URL: 'https://api.northstrat-app.com/test' }
};

const prod = {
  s3: { MEDIABUCKET: 'https://s3.amazonaws.com/mobileapp-prod-mediabucket' },
  apiGateway: { URL: 'https://api.northstrat-app.com/prod' }
};

const map = {
  DevAbbott,
  DevCook,
  test,
  prod
};

const config = map[process.env.REACT_APP_STAGE];
const constants = {
  notificationTypes: ['Urgent', 'Newsletter', 'Announcement', 'Event Reminder'],
  pointTypes: [
    'Certification',
    'Degree',
    'Escape Room',
    'Game Night',
    'LinkedIn Learning',
    'Ping Pong Tourney',
    'Study Group',
    'Workout'
  ],
  pointAmounts: [
    {
      action: 'Attended Event',
      amount: 5
    },
    {
      action: 'Charity Volunteer',
      amount: 10
    },
    {
      action: 'Organized Event',
      amount: 25
    },
    {
      action: 'Awarded/Won Prize',
      amount: 10
    },
    {
      action: 'Organized Project Team Event',
      amount: 10
    },
    {
      action: 'Organized Brown Bag',
      amount: 20
    },
    {
      action: 'Obtained Degree',
      amount: 25
    },
    {
      action: 'Obtained Certification',
      amount: 20
    },
    {
      action: 'Maintained Certification',
      amount: 10
    },
    {
      action: 'Workout/Fitness',
      amount: 1
    },
    {
      action: 'LinkedIn Learning',
      amount: 1
    }
  ],
  userGroupMappings: {
    ADMIN: 'Administrators',
    ALL: 'All',
    CO: 'Colorado',
    VA: 'Virginia',
    EMP: 'Employees'
  }
};

export default {
  ...config,
  ...constants
};
