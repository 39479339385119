import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Container } from 'reactstrap';
import Moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSignOutAlt,
  faAdjust,
  faUser,
  faCalendarAlt,
  faGlassCheers,
  faTrash,
  faComments,
  faMapPin,
  faTrophy,
  faLock,
  faLockOpen,
  faExclamationTriangle,
  faUserEdit,
  faUserPlus,
  faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer } from 'react-toastify';
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy';
import Support from '../Support/Support';
import AuthService from '../Authentication/AuthService';
import Messages from '../Messages/Messages';
import Confirm from '../Authentication/Confirm';
import SignIn from '../Authentication/SignIn';
import SignUp from '../Authentication/SignUp';
import Navigation from '../Navigation/Navigation';
import GroupManagement from '../GroupManagement/GroupManagement';
// import GroupManagement from '../GroupManagement/GroupHooks';
import Directory from '../Directory/Directory';
import UserManagement from '../UserManagement/UserManagement';
import Calendar from '../Calendar/Calendar';
import Media from '../Media/Media';
import Olympics from '../Olympics/Olympics';

library.add(faSignOutAlt,
  faAdjust,
  faUser,
  faCalendarAlt,
  faGlassCheers,
  faTrash,
  faComments,
  faMapPin,
  faTrophy,
  faLock,
  faLockOpen,
  faExclamationTriangle,
  faUserEdit,
  faUserPlus,
  faPlusSquare);

Moment.locale('en');
momentLocalizer();

const Auth = new AuthService();

const AuthRoute = ({ component: Comp, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      ((Auth.loggedIn() === true && Auth.isAdmin() === true) ? <Comp {...props} /> : <Redirect to='/signin' />)
    }
  />
);

export default class App extends Component {
  constructor(props) {
    super(props);
    this.Auth = new AuthService();
  }

  async componentDidMount() {
    try {
      const shouldSignOut = await this.Auth.shouldSignOut();
      if (shouldSignOut) {
        this.handleSignOut();
      }
    } catch (error) {
      throw error;
    }
  }

  handleSignOut = () => {
    const { history } = this.props;
    history.push('/signin');
  };

  render() {
    return (
      <div className='app'>
        {(Auth.loggedIn() === true && Auth.isAdmin() === true) && <Navigation signout={this.handleSignOut} />}
        <Container className='app-container'>
          <Switch>
            <Route
              exact
              path='/'
              component={SignIn}
            />
            <Route
              path='/confirm'
              component={Confirm}
            />
            <Route
              path='/signin'
              component={SignIn}
            />
            <Route
              path='/signup'
              component={SignUp}
            />
            <Route
              path='/privacy-policy'
              component={PrivacyPolicy}
            />
            <Route
              path='/support'
              component={Support}
            />
            <AuthRoute
              path='/home'
              component={Messages}
            />
            <AuthRoute
              path='/usermgmt'
              component={UserManagement}
            />
            <AuthRoute
              path='/olympics'
              component={Olympics}
            />
            <AuthRoute
              path='/groupmgmt'
              component={GroupManagement}
            />
            <AuthRoute
              path='/messages'
              component={Messages}
            />
            <AuthRoute
              path='/directory'
              component={Directory}
            />
            <AuthRoute
              path='/calendar'
              component={Calendar}
            />
            <AuthRoute
              path='/media'
              component={Media}
            />
          </Switch>
        </Container>
        <ToastContainer
          position='bottom-center'
          autoClose={3000}
        />
      </div>
    );
  }
}
