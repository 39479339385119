import React, { useState, useEffect } from 'react';
import { Button,
  Card,
  CardBody,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Row } from 'reactstrap';
import { toast } from 'react-toastify';
import moment from 'moment';
import { DateTimePicker, DropdownList } from 'react-widgets';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingBar from '../Utilities/LoadingBar';
import AuthService from '../Authentication/AuthService';
import UseWindowDimensions from '../Utilities/GetWindowDimensions';
import config from '../../config';

function Olympics() {
  const currentYear = '19';
  const { pointAmounts } = config;
  const fieldsNotToUpdate = [
    'janPoints',
    'febPoints',
    'marPoints',
    'aprPoints',
    'mayPoints',
    'junPoints',
    'julPoints',
    'augPoints',
    'sepPoints',
    'octPoints',
    'novPoints',
    'decPoints',
    'totalPoints'
  ];
  const { height } = UseWindowDimensions();
  const Auth = new AuthService();
  const [olympicsUsers, setOlympicsUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [newPoints, setNewPoints] = useState('');
  const [newPointsAction, setNewPointsAction] = useState(null);
  const [newPointsDate, setNewPointsDate] = useState(new Date());
  const [needsRefresh, refreshUsers] = useState(true);
  const [activeUser, setActiveUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [showUpdateModal, toggleUpdateModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const PointItem = ({ item }) => (
    <span>
      <strong>{item.action}</strong>
      {` : ${item.amount}`}
    </span>
  );

  const getPointsInMonth = (month, points) => {
    let monthPoints = 0;
    if (points && points.length > 0) {
      points.forEach((point) => {
      if (moment(parseInt(point.date, 10)).month() === month) {
          monthPoints += parseInt(point.count, 10);
      }
      });
      return monthPoints;
    }
    return monthPoints;
  };

  const addPoints = () => {
    const pointObj = {
      count: newPoints,
      date: moment(newPointsDate).valueOf(),
      type: newPointsAction
    };
    if (activeUser.points && activeUser.points.length) {
      activeUser.points.push(pointObj);
    } else {
      activeUser.points = [];
      activeUser.points.push(pointObj);
    }
    setNewPoints('');
    setNewPointsAction(null);
  };

  const updateUser = async (user) => {
    const req = {};
    const userKeys = Object.keys(user);
    userKeys.forEach((key) => {
      if (fieldsNotToUpdate.indexOf(key) < 0) {
        req[key] = user[key];
      }
    });
    try {
      await Auth.fetch('/directory', {
        method: 'PUT',
        body: JSON.stringify(req)
      });
      refreshUsers(true);
      toast.success('Points added!');
      refreshUsers(false);
    } catch (error) {
      toast.error(error.toString(), { autoClose: false });
    }
  };

  useEffect(() => {
    /**
     * @description Retrieves users from the Cognito UserPool
     */
    const getUsers = async () => {
      setLoading(true);
      try {
        const res = await Auth.fetch('/directory', { method: 'GET' });
        const employees = res.data.map((emp) => {
          emp.janPoints = getPointsInMonth(0, emp.points);
          emp.febPoints = getPointsInMonth(1, emp.points);
          emp.marPoints = getPointsInMonth(2, emp.points);
          emp.aprPoints = getPointsInMonth(3, emp.points);
          emp.mayPoints = getPointsInMonth(4, emp.points);
          emp.junPoints = getPointsInMonth(5, emp.points);
          emp.julPoints = getPointsInMonth(6, emp.points);
          emp.augPoints = getPointsInMonth(7, emp.points);
          emp.sepPoints = getPointsInMonth(8, emp.points);
          emp.octPoints = getPointsInMonth(9, emp.points);
          emp.novPoints = getPointsInMonth(10, emp.points);
          emp.decPoints = getPointsInMonth(11, emp.points);
          emp.totalPoints = emp.points ?
            emp.points.reduce((acc, val) => {
              const currentTotal = parseInt(val.count, 10) + acc;
              return currentTotal;
            }, 0) : 0;
          return emp;
        });
        employees.sort((a, b) => {
          return a.totalPoints > b.totalPoints ? -1 : 1;
        })
        setOlympicsUsers(employees);
        setFilteredUsers(employees);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error(error.toString(), { autoClose: false });
      }
    };
    if (needsRefresh) {
      getUsers();
      refreshUsers(false);
    }
  }, [needsRefresh, Auth]);

  useEffect(() => {
    setFilteredUsers(
      olympicsUsers.filter((user) => {
        return user.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.lastName.toLowerCase().includes(searchTerm.toLowerCase());
      })
    );
  }, [searchTerm, olympicsUsers]);

  return (
    <div className='component-container'>
      <div className='component-header card'>
        {loading && <LoadingBar />} <div className='component-header-text'>Olympics Points Management</div>
      </div>
      <div className='component-body'
        style={{
          maxHeight: height - 150
        }}>
        <Card>
          <CardBody>
            <FormGroup>
              <Input
                type='text'
                placeholder='Begin typing to find employees...'
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
              />
            </FormGroup>
            <ListGroup
              className='list-group-container'
              style={{
                height: height - 300,
                overflowY: 'auto'
              }}
            >
              <ListGroupItem className='d-flex justify-content-between align-items-center list-group-header'>
                <div className='list-group-col'/>
                <div className='list-group-col'/>
                <div className='list-group-col'>
                  {`Jan ${currentYear}`}
                </div>
                <div className='list-group-col'>
                  {`Feb ${currentYear}`}
                </div>
                <div className='list-group-col'>
                  {`Mar ${currentYear}`}
                </div>
                <div className='list-group-col'>
                  {`Apr ${currentYear}`}
                </div>
                <div className='list-group-col'>
                  {`May ${currentYear}`}
                </div>
                <div className='list-group-col'>
                  {`Jun ${currentYear}`}
                </div>
                <div className='list-group-col'>
                  {`Jul ${currentYear}`}
                </div>
                <div className='list-group-col'>
                  {`Aug ${currentYear}`}
                </div>
                <div className='list-group-col'>
                  {`Sep ${currentYear}`}
                </div>
                <div className='list-group-col'>
                  {`Oct ${currentYear}`}
                </div>
                <div className='list-group-col'>
                  {`Nov ${currentYear}`}
                </div>
                <div className='list-group-col'>
                  {`Dec ${currentYear}`}
                </div>
                <div className='list-group-col'>
                  Total Points
                </div>
              </ListGroupItem>
              {filteredUsers.map((user) => {
                return (
                  <ListGroupItem
                    className='d-flex justify-content-between align-items-center'
                    key={user.employeeId}
                  >
                    <div className='list-group-col'>
                      <Button
                        outline
                        color='primary'
                        size='sm'
                        onClick={() => {
                            setActiveUser({ ...user });
                            toggleUpdateModal(true);
                        }}>
                        <FontAwesomeIcon
                            icon='plus-square'
                            className='fa-hover'
                        />
                        {' '}
                        Add
                      </Button>
                    </div>
                    <div className='list-group-col'>
                      {`${user.firstName} ${user.lastName}`}
                    </div>
                    <div className='list-group-col'>
                      {user.janPoints}
                    </div>
                    <div className='list-group-col'>
                      {user.febPoints}
                    </div>
                    <div className='list-group-col'>
                      {user.marPoints}
                    </div>
                    <div className='list-group-col'>
                      {user.aprPoints}
                    </div>
                    <div className='list-group-col'>
                      {user.mayPoints}
                    </div>
                    <div className='list-group-col'>
                      {user.junPoints}
                    </div>
                    <div className='list-group-col'>
                      {user.julPoints}
                    </div>
                    <div className='list-group-col'>
                      {user.augPoints}
                    </div>
                    <div className='list-group-col'>
                      {user.sepPoints}
                    </div>
                    <div className='list-group-col'>
                      {user.octPoints}
                    </div>
                    <div className='list-group-col'>
                      {user.novPoints}
                    </div>
                    <div className='list-group-col'>
                      {user.decPoints}
                    </div>
                    <div className='list-group-col'>
                      {user.totalPoints}
                    </div>
                  </ListGroupItem>
                )
              })}
            </ListGroup>
          </CardBody>
        </Card>
      </div>
      <Modal
        isOpen={showUpdateModal}
        backdrop='true'
        size='lg'>
        <ModalHeader>{`${activeUser.firstName} ${activeUser.lastName}`}</ModalHeader>
        <Form>
          <ModalBody>
            <FormGroup>
              <ListGroup>
                <ListGroupItem>
                  <Row>
                    <Col sm={3}>
                      Date
                    </Col>
                    <Col sm={3}>
                      # Points
                    </Col>
                    <Col sm={3}>
                      Action
                    </Col>
                  </Row>
                  <Row form>
                    <Col sm={3}>
                      <DateTimePicker
                        time={false}
                        value={newPointsDate}
                        onChange={(date) => {
                          setNewPointsDate(date);
                        }}
                      />
                    </Col>
                    <Col sm={3}>
                      <FormGroup row>
                        <Col sm={10}>
                          <Input
                            type='number'
                            value={newPoints}
                            onChange={e => setNewPoints(e.target.value)}
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup row>
                        <Col sm={9}>
                          <DropdownList
                            data={pointAmounts}
                            value={newPointsAction}
                            itemComponent={PointItem}
                            textField='action'
                            valueField='action'
                            onChange={(val) => {
                                setNewPointsAction(val.action);
                                setNewPoints(val.amount);
                              }
                            }
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col
                      sm={2}
                      className='d-flex align-items-center'
                    >
                      <FormGroup>
                        <Button
                          disabled={!newPoints || !newPointsAction}
                          color='success'
                          size='sm'
                          onClick={() => addPoints()}
                        >
                          Add Points
                        </Button>
                      </FormGroup>
                    </Col>
                  </Row>
                </ListGroupItem>
                <ListGroup
                  style={{
                    maxHeight: 246,
                    overflowY: 'auto'
                  }}
                >
                  {activeUser.points
                    && activeUser.points
                      .sort((a, b) => {
                        return parseInt(a.date, 10) > parseInt(b.date, 10) ? -1 : 1;
                      })
                      .map((entry, idx) => {
                        const key = entry.date + idx;
                        return (
                          <ListGroupItem key={key}>
                            <Row>
                              <Col>
                                <strong>{entry.count}</strong>
                              </Col>
                              <Col style={{ textAlign: 'right' }}>
                                {moment.utc(parseInt(entry.date, 10)).format('ll')}
                                {' - '}
                                {entry.type}
                              </Col>
                            </Row>
                          </ListGroupItem>
                        );
                      })}
                </ListGroup>
                <ListGroupItem>
                  Total Points:
                  <strong>{` ${activeUser.totalPoints || 0}`}</strong>
                </ListGroupItem>
              </ListGroup>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              color='primary'
              onClick={() => {
                updateUser(activeUser);
                toggleUpdateModal(false);
              }}
            >
              Update
            </Button>
            <Button
              color='secondary'
              onClick={() => {
                toggleUpdateModal(false);
                setActiveUser({});
                setNewPointsAction(null);
                setNewPointsAction('')
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  )
};

export default Olympics;